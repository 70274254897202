<template>
  <div>
    <BaseForm :title="title" :mod="mod" :flat="flat" :divider="divider" :dialog="dialog" :loading="loading" @save="save">
      <template #content>
        <v-container fluid>
          <v-row
            ><v-col> <v-switch v-model="form.automaticBilling" label="Ativar/desativar cobrança automática"></v-switch> </v-col
          ></v-row>

          <v-row align="center">
            <v-col sm="6">
              <v-select
                class="required mb-2 mr-sm-2 mb-sm-0"
                :loading="loadingPlans"
                v-model="form.PlanId"
                @change="getPlanFeatures"
                :items="plans"
                label="Plano:"
                placeholder="Selecione o plano em que deseja anunciar"
                :disabled="mod === 'update'"
              >
              </v-select>
            </v-col>
            <v-col sm="6">
              <v-select
                :loading="loadingTimeIntervals"
                :items="timeIntervals"
                label="Intervalo de pagamento"
                v-model="form.TimeIntervalForPaymentId"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete
                ref="companiesAutocomplete"
                class="required"
                v-model="form.CompanyId"
                :loading="loadignCompanies"
                :items="companies"
                :search-input.sync="searchCompany"
                flat
                hide-no-data
                hide-details
                label="Empresa"
                item-text="text"
                item-value="value"
                @change="verifyIfHasAdvertiser"
                cache-items
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="selectedCompanyWithoutAdvertiser">
            <v-col>
              <v-alert border="top" colored-border type="error" elevation="2">
                Os dados do responsável pela empresa selecionada estão incompletos.
                <v-btn x-small outlined rounded color="error" @click="openUpdateScreen(form.CompanyId)">Completar cadastro</v-btn>
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                class="required"
                :loading="loadingCities"
                :items="cities"
                v-model="form.CityId"
                label="Cidade"
                :disabled="selectedCompanyWithoutAdvertiser"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                class="required"
                v-model="form.AdCategoryId"
                :items="adCategoryOptions"
                :loading="loadingAdCategories"
                label="Categoria"
                placeholder="Selecione a categoria em que deseja anunciar"
                :disabled="mod === 'update' || selectedCompanyWithoutAdvertiser"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #actions> </template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  directives: { mask },
  computed: {
    ...mapGetters("adverts", ["validateSpecificFeature"]),
    announcementId() {
      return this.$route.params.id;
    },
    saveButtonText() {
      return this.form.PlanId === 1 || this.form.PlanId === 2 ? "Salvar e publicar" : "Salvar e avançar";
    },
    saveButtonIcon() {
      return this.form.PlanId === 1 || this.form.PlanId === 2 ? "mdi-check" : "mdi-arrow-down-bold";
    },
  },
  props: {
    adData: {
      type: Object,
    },
    adFacilities: {
      type: Array,
    },
    adPhones: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      loadingAdCategories: false,
      loadignCompanies: false,
      loadingPlans: false,
      loadingCities: false,
      loadingTimeIntervals: false,
      selectedCompanyWithoutAdvertiser: false,
      actionAfterOperation: null,
      timeIntervals: [],
      adCategoryOptions: [],
      companies: [],
      plans: [],
      cities: [],
      selectedCategories: [],
      searchCompany: null,
      form: {
        coordinate: null,
        PlanId: null,
        automaticBilling: true,
      },
    };
  },
  watch: {
    searchCompany(val) {
      if (!val || val.length < 3) return;

      if (this.loadignCompanies) return;

      this.onSearchCompany(val);
    },
  },
  methods: {
    ...mapActions("adverts", ["setPlanFeatures", "setAdId"]),
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validateForm();
      // const errors = [];

      if (errors.length) {
        return this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        let formattedData = await this.formatData();

        const response = await this.$axios.post("/posts", formattedData);

        this.setAdId(response.data.id);

        this.$emit("saved", 1);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let formattedData = await this.formatData();

        await this.$axios.put(`/posts/${this.announcementId}`, formattedData);

        this.$emit("saved", 1);

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatData() {
      let auxForm = this.form;

      return auxForm;
    },
    validateForm() {
      const errors = [];

      if (!this.form.AdCategoryId) errors.push("Categoria do anúncio obrigatória");

      if (!this.form.PlanId) errors.push("Plano do anúncio obrigatório");

      if (!this.form.CityId) errors.push("Cidade do anúncio obrigatória");

      if (this.form.PlanId !== 1 && !this.form.TimeIntervalForPaymentId) errors.push("Intervalo de pagamento do anúncio obrigatório");

      if (!this.form.CompanyId) errors.push("Empresa do anúncio obrigatória");

      return errors;
    },
    async getTimeIntervals() {
      try {
        this.loadingTimeIntervals = true;

        const res = await this.$axios.get("/time-intervals");

        this.setTimeIntervalsData(res.data);

        this.loadingTimeIntervals = false;
      } catch (error) {
        this.loadingTimeIntervals = false;
        this.handleErrors(error);
      }
    },
    setTimeIntervalsData(intervals) {
      this.timeIntervals = intervals.map((t) => {
        return {
          value: t.id,
          text: t.name,
        };
      });
    },
    verifyIfHasAdvertiser(companyId) {
      if (!companyId || !this.companies || !this.companies.length) return;

      this.selectedCompanyWithoutAdvertiser = false;

      if (!this.companies.find((c) => c.value === companyId).hasAdvertiser) this.selectedCompanyWithoutAdvertiser = true;
    },
    async getAdCategories() {
      try {
        this.loadingAdCategories = true;

        const res = await this.$axios.get("/posts/categories");

        this.setCategoriesData(res.data);

        this.loadingAdCategories = false;
      } catch (error) {
        this.loadingAdCategories = false;
        this.handleErrors(error);
      }
    },
    setCategoriesData(categories) {
      this.adCategoryOptions = categories.map((c) => {
        return {
          value: c.id,
          text: c.name,
        };
      });
    },
    onSearchCompany(search) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.getCompanies(search);
      }, 800);
    },
    async getCompanies(search) {
      try {
        this.loadignCompanies = true;

        const res = await this.$axios.get(`/companies?search=${search}`);

        this.setCompaniesData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadignCompanies = false;
      }
    },
    setCompaniesData(companies) {
      this.companies = companies.map((c) => {
        return {
          value: c.id,
          text: `${c.companyName}`,
          hasAdvertiser: c.Advertiser ? true : false,
        };
      });
    },
    async getPlans() {
      try {
        this.loadingPlans = true;

        const res = await this.$axios.get("/plans");

        this.setPlansData(res.data);

        this.loadingPlans = false;
      } catch (error) {
        this.loadingPlans = false;
        this.handleErrors(error);
      }
    },
    setPlansData(plans) {
      this.plans = plans.map((p) => {
        return {
          value: p.id,
          text: `${p.name} (R$ ${parseFloat(p.value).toFixed(2).replace(".", ",")}/mês)`,
        };
      });
    },
    async getPlanFeatures(planId) {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/plans/${planId}/features`);

        this.setPlanFeatures(res.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    async loadCities() {
      try {
        this.loadingCities = true;

        let url = `/cities`;

        const response = await this.$axios.get(url);

        this.setCities(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingCities = false;
      }
    },
    setCities(data) {
      const parsedData = [...data];

      this.cities = parsedData.map((c) => ({
        text: `${c.name} - ${c.uf}`,
        value: c.id,
      }));
    },
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form.coordinate = `${position.coords.latitude}, ${position.coords.longitude}`;
          },
          () => {
            this.handleErrors("Erro ao pegar localização");
          }
        );
      } else {
        this.handleErrors("Geolocalização não suportada pelo navegador");
        return;
      }
    },
    setAnnouncementData() {
      this.form = { ...this.adData };
    },
    openUpdateScreen(companyId) {
      this.$router.push(`/clientes/${companyId}/editar`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = {};
    },
  },
  created() {
    this.getAdCategories();
    this.getPlans();
    this.getTimeIntervals();
    this.loadCities();

    if (this.mod === "update") {
      this.setAnnouncementData();
    }
  },
};
</script>

<style></style>
